import React from 'react'
import Layout from '../components/layout'
import ImageTextBlock from '../components/imageTextBlock/imageTextBlockView'
import { graphql} from 'gatsby'





const OpeningstijdenIndex = (props) => {

  const textBlokken = props.data.allContentfulOpeningsTijdenPage.nodes[0].imageTextBlocks;




    return (
      <Layout title={props.data.allContentfulOpeningsTijdenPage.nodes[0].title} location={props.location}>
        <div className='empty_box'></div>
        <h1 style={{textAlign: 'center'}}>{props.data.allContentfulOpeningsTijdenPage.nodes[0].title}</h1>
        <div style={{display: 'flex', flexWrap: 'wrap', maxWidth: 'var(--size-max-width)', justifyContent: 'space-between', margin: '0 auto' }}>
            {(textBlokken).map((blok) => <ImageTextBlock image={blok.image?.gatsbyImageData} heading={blok.heading} small={!blok.largeBlock} content={blok.text}/> )  }
        </div>
      </Layout>

    )
  }


export default OpeningstijdenIndex

export const PageQuery = graphql`
query OpeningstijdenQuery {
  allContentfulOpeningsTijdenPage {
    nodes {
      title
      imageTextBlocks {
        image {
          gatsbyImageData
        }
        heading
        text {
          raw
        }
        largeBlock
      }
    }
  }
}
`





